export default class AutoplayAudio {
  private isPlaying: boolean;
  private id: string;
  private audioEl: HTMLAudioElement;
  private html: HTMLElement;

  constructor(id: string) {
    this.isPlaying = false;
    this.id = id;
    this.audioEl = document.querySelector(this.id) as HTMLAudioElement;
    this.html = document.getElementsByTagName('html') as any;
  }

  autoplay() {
    if (!this.audioEl) return;

    if (this.audioEl.played) {
      this.audioEl.muted = false;
    }

    // play audio on click
    this.html[0].addEventListener('click', () => this.playAudio());
  }

  private playAudio() {
    if (!this.audioEl) return;

    if (this.audioEl.paused) {
      this.audioEl.currentTime = 0; // sets time to 0
      this.audioEl.play(); // play the audio
      this.audioEl.muted = false; // off the muted
    }
  }
}
