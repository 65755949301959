export default function VideoFallback() {
  var v = document.getElementById('mobile-video') as HTMLVideoElement;
  const mobileSrc = './300Ent_DNA.a59bb9e5.gif';

  fallback(); // added fallback

  function fallback() {
    var sources = v!.querySelectorAll('source'),
      lastsource = sources[sources.length - 1];

    v.play(); // plays the video
    // v.pause();

    lastsource.addEventListener(
      'error',
      function (ev) {
        var d = document.createElement('img');

        d.src = mobileSrc;
        d.className = 'w-auto h-full flex m-auto object-cover';
        d.id = 'mobile-gif';
        d.alt = 'test';

        d.innerHTML = v.innerHTML;
        v.parentNode!.replaceChild(d, v);
      },

      false,
    );

    if (v.paused) {
      var d = document.createElement('img');

      d.src = mobileSrc;
      d.className = 'freezeframe w-auto h-full flex m-auto object-cover';
      d.id = 'mobile-gif';
      d.alt = 'test';

      v.parentNode!.replaceChild(d, v);
    }
  }
}
