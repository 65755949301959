export default class LastFrame {
  private target: HTMLImageElement[];
  private HTMLContainer: HTMLElement;
  public isClicked: boolean;

  constructor(target: string) {
    this.target = document.querySelectorAll(target) as any;
    this.isClicked = false;
    this.HTMLContainer = document.getElementsByTagName('html')[0];
  }

  private _doSomething() {
    for (let i = 0; i <= this.target.length - 1; i++) {
      const el = this.target[i];
      const elStyle = el.style; // element and style

      // classes
      el.classList.remove('absolute');

      // hide all children in parentElement
      const parentChildElements = el.parentElement!.children as any;
      parentChildElements[0].style.display = 'none';
      parentChildElements[1].style.display = 'none';

      // show all element
      elStyle.zIndex = '12321312';
      elStyle.opacity = '100%';
      elStyle.transition = 'all .3s ease-in-out';

      // show `enter website` btn
      const enterWebsite = document.getElementById('enter-website');
      enterWebsite?.classList.remove('hidden');
      enterWebsite?.classList.remove('opacity-0');
      enterWebsite?.classList.add('opacity-100');
    }
  }

  private _handleClick() {
    this.HTMLContainer.addEventListener('click', () => {
      this._doSomething(); // do something on htmlcontainer click
      this.isClicked = true;
    });
  }

  showPopup() {
    this._doSomething();
    this.isClicked = true;
  }

  init() {
    this._handleClick(); // handle click
  }
}
