// styles
import './styles/main.css';
import './styles/components/video-container.css';
import './fonts/friz-quadrata/stylesheet.css';

import VideoFallback from './ts/video-fallback';
import TimedPopup from './ts/timed-popup';
import HandlingGIF from './ts/handling-gif';
import AutoplayAudio from './ts/autoplay-audio';
import LastFrame from './ts/last-frame';

(function () {
  window.onload = () => {
    const isDeviceLarge = window.innerWidth >= 768;

    // get the video based on screen width
    let timedPopup;
    if (isDeviceLarge) {
      timedPopup = new TimedPopup(document.getElementById('enter-website') as any, document.getElementById('desktop-video') as any);
    } else {
      timedPopup = new TimedPopup(document.getElementById('enter-website') as any, document.getElementById('mobile-video') as any);
    }

    const audioAutoplay = new AutoplayAudio('#hidden-audio');
    const lastFrame = new LastFrame('.last-frame');

    VideoFallback();

    // init
    timedPopup.init();
    audioAutoplay.autoplay();
    lastFrame.init();

    const mobileGif = document.getElementById('mobile-gif');
    if (mobileGif) {
      setTimeout(() => {
        const mobileLastFrame = new LastFrame('.last-frame');
        mobileLastFrame.showPopup();
      }, 33500);
    }
  };
})();
