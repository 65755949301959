export default class TimedPopup {
  private container: HTMLDivElement;
  private targetVideo: HTMLVideoElement;

  constructor(container: HTMLDivElement, targetVideo: HTMLVideoElement) {
    this.container = container;
    this.targetVideo = targetVideo;
  }

  private startPopup() {
    this.targetVideo.addEventListener('ended', () => {
      this.container.classList.remove('opacity-0');
      this.container.classList.remove('hidden');
      this.container.classList.add('opacity-100');
    });
  }

  init() {
    this.startPopup();
  }
}
